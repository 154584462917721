import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          We'll be back soon! Our team is working hard to enhance your experience. Thank you for waiting.
        </p>
        <button
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
          onClick={() => window.location.href = 'https://app.reconplus.co.uk'}
        >
          Go to Admin Page
        </button>
      </header>
    </div>
  );
}

export default App;
